<template>
  <div class="body-container">
    <div class="d-flex flex-column mt-2">
      <div class="mt-3 header-text"><strong>How it works:</strong></div>
      <div class="mt-3 col-10 paragraph-text">
        <div class="mb-3">Each week you are given football fixtures to predict the score of. The points allocated are
          based on the actual match result.
        </div>
        <div class="mb-3">
          Each point earned counts as an entry towards the draw to <strong>WIN 1 of 5 cash prizes of R2000</strong>
        </div>
        <div class="mb-2">The draw for the monthly winners will be done on the following dates</div>
        <table class="table-borderless col-12 mb-3">
          <thead>
          <tr>
            <td class="col-8">Draw Number</td>
            <td>Draw Date</td>
          </tr>
          </thead>
          <tr>
            <td>1</td>
            <td>2 April 2025</td>
          </tr>
          <tr>
            <td>2</td>
            <td>7 May 2025</td>
          </tr>
          <tr>
            <td>3</td>
            <td>4 June 2025</td>
          </tr>
          <tr>
            <td>4</td>
            <td>2 July 2025</td>
          </tr>
          <tr>
            <td>5</td>
            <td>6 August 2025</td>
          </tr>
        </table>
        <div class="mb-3">The Points Breakdown are as follows:</div>
        <table class="table-borderless col-12 mb-3">
          <tbody>
          <tr>
            <td class="col-8">Home Score Correct</td>
            <td>6 Points</td>
          </tr>
          <tr>
            <td>Away Score Correct</td>
            <td>6 Points</td>
          </tr>
          <tr>
            <td>Correct Result</td>
            <td>10 Points</td>
          </tr>
          <tr>
            <td>All Correct</td>
            <td>10 Points</td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex flex-column align-items-center">
          <button @click="routeToLandingPage()" class="back-button col-11">Back
          </button>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center mt-3">
        <u class="terms-conditions-text" @click="termsConditionsClicked()">View Terms and Conditions</u>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: "HowItWorks",
  computed: {
    ...mapState(['isLoading'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    routeToLandingPage() {
      this.$router.push('/landing-page');
    },
    termsConditionsClicked() {
      my.postMessage('terms');
    },
  },
  mounted() {
    let webViewScript = document.createElement('script')
    webViewScript.setAttribute('src', 'https://appx/web-view.min.js')
    document.head.appendChild(webViewScript)
  },
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
}

.header-text {
  margin-left: 20px;
}

.paragraph-text {
  margin-left: 30px;
}

table {
  font-size: 12px;
  border-collapse: collapse;
}

.back-button {
  background-color: #EF151C;
  color: #FFFFFF;
  height: 50px;
  border-radius: 10px;
  border: 0;
  font-size: 16px;
}

.terms-conditions-text {
  font-weight: 700;
  cursor: pointer;
}

td {
  font-size: 11px;
}

</style>
